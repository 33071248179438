import React, { useContext, useEffect, useState } from "react";
import L from "leaflet";

const MapContext = React.createContext({});

export const MapContextProvider = ({ children }) => {
  const [driverPosition, setDriverPosition] = useState([0, 0]);
  const [storePosition, setStorePosition] = useState([0, 0]);
  const [destinationPosition, setDestinationPosition] = useState([0, 0]);
  const [message, setMessage] = useState("");
  const [driverName, setDriverName] = useState("");
  const [storeInfo, setStoreInfo] = useState("");
  const [topMargin, setTopMargin] = useState(0);
  const [bounds, setBounds] = useState(
    L.latLngBounds([L.latLng(36, 26), L.latLng(41, 45)])
  );

  useEffect(() => {
    const positions = [driverPosition, storePosition, destinationPosition];

    const validPositions = positions.filter(
      // If a position is null or undefined or its one of coordinate is 0, do not include that to setBounds
      (position) => position && position[0] && position[1]
    );

    const latitudes = validPositions.map((position) => position[0]);
    const longitudes = validPositions.map((position) => position[1]);

    if (latitudes.length > 0) {
      // If there is a valid position
      const minLat = Math.min(...latitudes);
      const maxLat = Math.max(...latitudes);
      const minLng = Math.min(...longitudes);
      const maxLng = Math.max(...longitudes);

      const marginLongitudeLng = (maxLng - minLng) * 0.04; // %4 margin
      const marginLatitudeLat = (maxLat - minLat) * 0.04; // %4 margin

      setBounds(
        L.latLngBounds([
          L.latLng(minLat - marginLatitudeLat, minLng - marginLongitudeLng),
          L.latLng(maxLat + marginLatitudeLat, maxLng + marginLongitudeLng),
        ])
      );
    }
  }, [driverPosition, storePosition, destinationPosition]);

  useEffect(() => {
    console.log("bounds", bounds);
  }, [bounds]);

  return (
    <MapContext.Provider
      value={{
        driverPosition,
        setDriverPosition,
        storePosition,
        setStorePosition,
        destinationPosition,
        setDestinationPosition,
        message,
        setMessage,
        driverName,
        setDriverName,
        storeInfo,
        setStoreInfo,
        topMargin,
        setTopMargin,
        bounds,
        setBounds,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => useContext(MapContext);
