import constants from "./common/constants";
import { useMapContext } from "./mapContext";
import { useTheme } from "./ThemeContext";

export default function StatusBar() {
  const { themeValues } = useTheme();
  const { message } = useMapContext();

  return (
    <div
      className={`statusbar`}
      style={{
        backgroundColor: themeValues.backgroundColor,
        color: themeValues.color,
        fontFamily: themeValues.fontFamily,
      }}
    >
      {message ? constants.ORDER_STATUSES[message] : ""}
    </div>
  );
}
