import { useEffect, useRef } from "react";

import { useTheme } from "./ThemeContext";
import { useMapContext } from "./mapContext";

import driverImage from "./assets/images/driver.png";
import storeImage from "./assets/images/store.png";

export default function InfoBar() {
  const { themeValues } = useTheme();
  const { driverName, storeInfo, setTopMargin } = useMapContext();
  const infoBarRef = useRef();

  useEffect(() => {
    if (infoBarRef?.current) {
      const rect = infoBarRef.current.getBoundingClientRect();
      setTopMargin(rect.bottom);
    }
  }, [infoBarRef]);

  return (
    <div
      ref={infoBarRef}
      className={`infobar`}
      style={{
        backgroundColor: themeValues.backgroundColor,
        color: themeValues.color,
        fontFamily: themeValues.fontFamily,
      }}
    >
      <div className={`infobar-half`}>
        <div className={`infobar-image`}>
          <img
            style={{
              height: "40px",
              borderRadius: 30,
              objectFit: "cover",
              borderWidth: "1px",
              borderStyle: "solid",
              backgroundColor: "white",
            }}
            src={driverImage}
            alt="Driver"
          />
        </div>
        <div className={`infobar-info`}>
          <div style={{ fontWeight: "bold" }}>Kurye</div>
          <div>{driverName}</div>
        </div>
      </div>
      <div className={`infobar-half`}>
        <div className={`infobar-image`}>
          <img
            style={{
              height: "40px",
              borderRadius: 30,
              objectFit: "fill",
              borderWidth: "1px",
              borderStyle: "solid",
              backgroundColor: "white",
            }}
            src={storeImage}
            alt="Store"
          />
        </div>
        <div className={`infobar-info`}>
          <div style={{ fontWeight: "bold" }}>Şube Bilgisi</div>
          <div>{storeInfo}</div>
        </div>
      </div>
    </div>
  );
}
