import React, { memo, useEffect, useMemo, useState } from "react";

import { MapContainer, Marker, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { Map } from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import { useMapContext } from "../mapContext";

import driverImage from "../assets/images/driver.png";
import storeImage from "../assets/images/store.png";
import destinationImage from "../assets/images/destination.png";

export default function LeafletMap() {
  const {
    driverPosition,
    storePosition,
    destinationPosition,
    topMargin,
    bounds,
  } = useMapContext();

  const UpdateBounds = memo(({ bounds }) => {
    // Mapcontainer is immutable. Bounds of MapContainer cannot  be changed
    // dynamically. Instead, use UpdateBounds function
    const map = useMap();
    map.fitBounds(bounds);
    return null;
  });

  const UpdateMapStyle = memo(({ window, topMargin }) => {
    const map = useMap();
    map.invalidateSize();
    return null;
  });

  return (
    <div
      style={{
        height: `${window.innerHeight - topMargin}px`,
        top: `${topMargin}px`,
        position: "relative",
      }}
    >
      <MapContainer
        key={"map"}
        zoomControl={false} // Disable the zoom control buttons
        scrollWheelZoom={false} // Disable zooming with the mouse scroll wheel
        doubleClickZoom={false} // Disable zooming with double-click
        dragging={false} // Disable dragging/panning
        touchZoom={false} // Disable pinch zoom
        attributionControl={false}
        style={{
          height: "100%",
        }}
        bounds={bounds} //Auto zoom and center depending on driverPosition, storePosition and destinationPosition
      >
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyCkEAThBS_VTtcWHCMK0gaNQDKF3NxGJTU" //AIzaSyCjM3R1tG72H8b3Ah6v1YMtWI4hSNjtJlE
          type={"roadmap"}
          styles={[
            //Remove labels on map
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi.business",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "road",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            },
          ]}
        />
        <UpdateBounds bounds={bounds} />
        <UpdateMapStyle window={window} topMargin={topMargin} />

        {driverPosition[0] && driverPosition[1] ? (
          <Marker
            position={driverPosition}
            icon={
              new L.Icon({
                iconUrl: driverImage,
                iconSize: [50, 50],
                className: "",
              })
            }
          >
            {/* <Popup>
          {driverName}
        </Popup> */}
          </Marker>
        ) : null}

        {storePosition[0] && storePosition[1] ? (
          <Marker
            position={storePosition}
            icon={
              new L.Icon({
                iconUrl: storeImage,
                iconSize: [50, 50],
                className: "",
              })
            }
          >
            {/* <Popup>{storeInfo}</Popup> */}
          </Marker>
        ) : null}

        {destinationPosition[0] && destinationPosition[1] ? (
          <Marker
            position={destinationPosition}
            icon={
              new L.Icon({
                iconUrl: destinationImage,
                iconSize: [50, 50],
                className: "",
              })
            }
          >
            {/* <Popup>{"Teslimat Adresi"}</Popup> */}
          </Marker>
        ) : null}
      </MapContainer>
    </div>
  );
}
