import React, { createContext, useState, useContext, useEffect } from "react";
import iugoLogo from "./assets/images/favicon_iugo.ico";

const ThemeContext = createContext();

const themes = {
  dark: {
    backgroundColor: "rgb(35 55 75 / 90%)",
    color: "#fff",
    fontFamily: "Arial",
    logo: iugoLogo,
    tabTitle: "Customer Tracking",
  },
  light: {
    backgroundColor: "#eee",
    color: "#222",
    fontFamily: "Times New Roman",
    logo: "https://stackoverflow.com/favicon.ico",
    tabTitle: "Light Theme",
  },
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("dark"); // Default theme is 'light'

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    document.title = themes[theme].tabTitle;

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = themes[theme].logo;
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{ themeValues: themes[theme], theme: theme, toggleTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
