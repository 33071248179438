import React, { useEffect, useState } from "react";

import { ThemeProvider } from "./ThemeContext";
import { MapContextProvider, useMapContext } from "./mapContext";

import StatusBar from "./StatusBar";
import InfoBar from "./InfoBar";

import "./App.css";

import LeafletMap from "./leaflet/LeafletMap";
import OrderWebSocket from "./common/websocket/websocket";

let ws = {};
let listenerID;

export default function App() {
  const [hash, setHash] = useState("");
  const {
    setMessage,
    setDriverPosition,
    setStorePosition,
    setDestinationPosition,
    setDriverName,
    setStoreInfo,
  } = useMapContext();

  useEffect(() => {
    const hashValue = window.location.pathname;
    setHash(hashValue);
  }, []);

  const handleSocketMessage = (socketMessage) => {
    if (socketMessage?.Type === "ERROR") {
      setMessage("ERROR");
      setDriverPosition([0, 0]);
      setStorePosition([0, 0]);
      setDestinationPosition([0, 0]);
      setDriverName("");
      setStoreInfo("");
    }

    if (socketMessage?.Type === "location") {
      let data = socketMessage?.Data;
      setMessage(data?.status);
      setDriverPosition([data?.driver_lat || 0, data?.driver_lon || 0]);
      setStorePosition([data?.store_lat || 0, data?.store_lon || 0]);
      setDestinationPosition([
        data?.customer_lat || 0,
        data?.customer_lon || 0,
      ]);
      setDriverName(data?.driver_name);
      setStoreInfo(data?.store_name);
    }
  };

  useEffect(() => {
    if (hash.length > 0) {
      ws.instance = new OrderWebSocket(hash);
      ws.instance.initSocket();
      listenerID = ws.instance.subscribe(handleSocketMessage);
    }

    return function cleanup() {
      if (ws?.instance) {
        if (ws.instance.closeSocket) {
          ws.instance.closeSocket();
          delete ws.instance;

          setMessage();
          setDriverPosition();
          setStorePosition();
          setDestinationPosition();
          setDriverName();
          setStoreInfo();
        }
      }
    };
  }, [hash]);

  return (
    <ThemeProvider>
      <div className="root">
        <StatusBar />
        <InfoBar />
        <LeafletMap />
      </div>
    </ThemeProvider>
  );
}
