import config from "./config";

class OrderWebSocket {
  #endpoint = "";
  #isSocketConnected = false;
  #socket;
  #url = config.websocketURI;
  #listeners = [];
  #socketRequested = false;

  constructor(endpoint) {
    this.#endpoint = endpoint;
  }

  onSocketError = (d) => {
    this.#isSocketConnected = false;
  };

  onSocketOpen = (d) => {
    this.#isSocketConnected = true;
  };

  onSocketClose = (d) => {
    this.#isSocketConnected = false;
  };

  onSocketMessage = (d) => {
    if (d.data.length == 0) {
      return;
    }
    var socketMessage = JSON.parse(d.data);
    this.notify(socketMessage);
  };

  initSocket() {
    this.#socket = new WebSocket(this.#url + "" + this.#endpoint);

    this.#socket.onopen = this.onSocketOpen;
    this.#socket.onclose = this.onSocketClose;
    this.#socket.onerror = this.onSocketError;
    this.#socket.onmessage = this.onSocketMessage;

    this.#socketRequested = true;

    if (!this.clockCallSocket) {
      this.clockCallSocket = setInterval(() => {
        if (this.#socketRequested && !this.#isSocketConnected) {
          this.initSocket();
        }
      }, 10000);
    }
  }

  closeSocket() {
    clearInterval(this.clockCallSocket);
    this.clockCallSocket = undefined;

    this.#socket.close();
    this.#socket = null;

    this.#socketRequested = false;
  }

  sendSocketMessage(message) {
    switch (this.#socket?.readyState) {
      case WebSocket.OPEN:
        this.#socket.send(JSON.stringify(message));
        break;
      case WebSocket.CONNECTING:
        this.#socket.addEventListener(
          "open",
          () => {
            this.sendSocketMessage(message);
          },
          { once: true }
        );
        break;
      default:
        console.error("Cannot send message. Socket is not initialized.");
        break;
    }
  }

  subscribe(method) {
    this.#listeners.push(method);
    return this.#listeners.length;
  }

  unsubscribe(subKey) {
    this.#listeners = this.#listeners.filter((val, key) => key !== subKey);
  }

  async notify(message) {
    this.#listeners.forEach((cb) => cb(message));
  }
}

export default OrderWebSocket;
