export default {
  ORDER_STATUSES: {
    IN_ORDER: "Siparişte",
    ON_DOOR: "Kapıda",
    RETURNING: "Dönüyor",
    PENDING: "Bekliyor",
    PREPARING: "Hazırlanıyor",
    UNASSIGNED: "Atanmadı",
    ON_ROAD: "Yolda",
    COMPLETED: "Tamamlandı",
    CANCELLED: "İptal Edildi",
  },
};
